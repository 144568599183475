<template>
  <div class="card card-custom">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop rounded" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::loading -->

    <!-- begin::Error -->
    <template v-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop" />
        <div class="loading-block w-75">
          <error-translation-alert
            ref="errorTranslationAlert"
            :error="activeError"
            class="shadow"
          />
        </div>
      </div>
    </template>
    <!-- end::Error -->

    <div class="card-body d-flex flex-column p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer pb-0">
        <div class="d-flex flex-column mr-2">
          <span class="text-dark font-weight-bold font-size-h5">Notifications</span>
        </div>
      </div>

      <div class="px-10 py-5 mt-n5">
        <b-carousel>
          <b-carousel-slide v-for="(notification, idx) in notifications" :key="`carousel.notification.${idx}`">
            <template v-slot:img>
              <div class="navi navi-icon-circle navi-spacer-x-0">
                <notification-nav-item :notification="notification" />
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { errorComponentMixin } from '@vedicium/core-vue';

import Equipment from '@/libs/classes/equipment';

import notificationNavItem from '@/components/pages/sunbeds/view/components/notification.nav-item.vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    notificationNavItem,
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,
      data: null,
    };
  },
  computed: {
    notifications () {
      if (!this.data || !this.data.results || this.data.results.length === 0) {
        return [];
      }

      const notifications = [];

      // Check for data
      if (this.data && this.data.results) {
        // Offline devices
        const offlineDevices = (this.data.results || []).filter((row) => row.state === 'offline', []).map((row) => row.total, 0).reduce((a, b) => a + b, 0);
        if (offlineDevices > 0) {
          notifications.push({
            icon: 'wifi-slash',
            text: `${offlineDevices} ${this.$tc('plural.sunbeds', offlineDevices)} not connected to (wireless) internet`,
            variant: 'danger',
          });
        }

        // Devices in error
        const devicesInError = (this.data.results || []).filter((row) => row.state === 'controller-error', []).map((row) => row.total, 0).reduce((a, b) => a + b, 0);
        if (devicesInError > 0) {
          notifications.push({
            icon: 'times-circle',
            text: `${devicesInError} ${this.$tc('plural.sunbeds', devicesInError)} currently in error`,
            variant: 'danger',
          });
        }

        // Equipment without modules
        const notLinkedEquipment = (this.data.results || []).filter((row) => row.state === 'no-device', []).map((row) => row.total, 0).reduce((a, b) => a + b, 0);
        if (notLinkedEquipment > 0) {
          notifications.push({
            icon: 'unlink',
            text: `${notLinkedEquipment} ${this.$tc('plural.sunbeds', notLinkedEquipment)} not linked to the corresponding MyLuxura modules`,
            variant: 'danger',
          });
        }
      }

      // If no notifications, add notification that everything is fine
      if (notifications.length === 0) {
        notifications.push({
          icon: 'check',
          text: 'All sunbeds are functioning properly',
          variant: 'success',
        });
      }

      return notifications;
    },
  },

  async mounted () {
    try {
      await this.getChartData();
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async getChartData (options = {}) {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', null);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/states`, { ...options }));
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.data;
    },
  },
};
</script>
