/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */

import LuxuraLog from '@/libs/classes/luxura_log';

export default class LuxuraLogError extends LuxuraLog {
  // Scheme
  error = {
    id: null,
    status: null,
    critical: null,
  }
}
