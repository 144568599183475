var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom"},[(_vm.isMounted === false || _vm.isLoading)?[_vm._m(0)]:_vm._e(),(_vm.isErrorActive)?[_c('div',{staticClass:"loading-container loading-overlap"},[_c('div',{staticClass:"loading-backdrop"}),_c('div',{staticClass:"loading-block w-75"},[_c('error-alert',{ref:"errorAlert",staticClass:"shadow",attrs:{"error":_vm.activeError}})],1)])]:_vm._e(),_c('div',{staticClass:"card-header flex-wrap"},[_vm._m(1),_c('div',{staticClass:"card-toolbar w-100 w-md-auto"},[_c('b-dropdown',{ref:"range-dropdown",staticClass:"btn-block btn-clean btn-dropdown dropdown-menu-top-unround",attrs:{"variant":"primary","right":"","lazy":"","no-caret":"","menu-class":"w-100 w-md-500px"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"mt-n1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'calendar-alt']}})],1),_c('span',{staticClass:"pl-3"},[(_vm.$moment(_vm.datePicker.range.start).isSame(_vm.datePicker.range.end, 'date'))?[_vm._v(_vm._s(_vm.$moment(_vm.datePicker.range.start).format("LL")))]:[_vm._v(_vm._s(_vm.$moment(_vm.datePicker.range.start).format("LL"))+" - "+_vm._s(_vm.$moment(_vm.datePicker.range.end).format("LL")))]],2)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 border-bottom col-md-8 border-bottom-md-0 border-right-md"},[_c('date-picker',{staticClass:"border-0 my-auto",attrs:{"mode":"date","color":"primary","locale":"en","is-range":"","is-expanded":"","value":_vm.datePicker.range,"min-date":_vm.datePicker.minDate,"max-date":_vm.datePicker.maxDate,"input-props":{ class: 'border-0' },"first-day-of-week":2},on:{"input":_vm.onDatePickerInput}})],1),_c('div',{staticClass:"col pt-5 pt-md-0"},[_c('div',{staticClass:"btn btn-outline-primary btn-block",on:{"click":function($event){$event.preventDefault();_vm.onDatePickerInput({
                  start: _vm.$moment().startOf('day').toDate(),
                  end: _vm.$moment().endOf('day').toDate()
                })}}},[_vm._v(" Today ")]),_c('div',{staticClass:"btn btn-outline-primary btn-block",on:{"click":function($event){$event.preventDefault();_vm.onDatePickerInput({
                  start: _vm.$moment().subtract(1, 'days').startOf('day').toDate(),
                  end: _vm.$moment().subtract(1, 'days').endOf('day').toDate()
                })}}},[_vm._v(" Yesterday ")]),_c('div',{staticClass:"btn btn-outline-primary btn-block",on:{"click":function($event){$event.preventDefault();_vm.onDatePickerInput({
                  start: _vm.$moment().startOf('isoWeek').toDate(),
                  end: _vm.$moment().toDate()
                })}}},[_vm._v(" This week ")]),_c('div',{staticClass:"btn btn-outline-primary btn-block",on:{"click":function($event){$event.preventDefault();_vm.onDatePickerInput({
                  start: _vm.$moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                  end: _vm.$moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                })}}},[_vm._v(" Last week ")]),_c('div',{staticClass:"btn btn-outline-primary btn-block",on:{"click":function($event){$event.preventDefault();_vm.onDatePickerInput({
                  start: _vm.$moment().startOf('month').toDate(),
                  end: _vm.$moment().toDate()
                })}}},[_vm._v(" This month ")]),_c('div',{staticClass:"btn btn-outline-primary btn-block",on:{"click":function($event){$event.preventDefault();_vm.onDatePickerInput({
                  start: _vm.$moment().subtract(1, 'month').startOf('month').toDate(),
                  end: _vm.$moment().subtract(1, 'month').endOf('month').toDate()
                })}}},[_vm._v(" Last month ")])])])]},proxy:true}])})],1)]),_c('apexchart',{ref:"chart",staticClass:"card-rounded-bottom h-400 mt-auto",attrs:{"type":_vm.chart.type,"height":_vm.chart.height,"options":_vm.chart.options,"series":_vm.chart.series}})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-container loading-overlap"},[_c('div',{staticClass:"loading-backdrop rounded"}),_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',{staticClass:"float-left"},[_vm._v("Gathering data...")]),_c('span',{staticClass:"spinner spinner-primary ml-auto"})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Sessions "),_c('span',{staticClass:"text-muted pt-2 font-size-sm d-block"},[_vm._v("Sessions of devices that belong to this company.")])])])}]

export { render, staticRenderFns }