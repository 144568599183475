<template>
  <div class="card card-custom">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop rounded" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::loading -->

    <!-- begin::Errors alert -->
    <template v-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop" />
        <div class="loading-block w-75">
          <error-alert
            ref="errorAlert"
            :error="activeError"
            class="shadow"
          />
        </div>
      </div>
    </template>
    <!-- end::Error alert -->

    <!-- begin::Card header -->
    <div class="card-header flex-wrap">
      <div class="card-title">
        <h3 class="card-label">
          Sessions
          <span class="text-muted pt-2 font-size-sm d-block">Sessions of devices that belong to this company.</span>
        </h3>
      </div>

      <div class="card-toolbar w-100 w-md-auto">
        <b-dropdown
          ref="range-dropdown"
          variant="primary"
          class="btn-block btn-clean btn-dropdown dropdown-menu-top-unround"
          right
          lazy
          no-caret
          menu-class="w-100 w-md-500px"
        >
          <template v-slot:button-content>
            <i class="mt-n1">
              <font-awesome-icon :icon="['fas', 'calendar-alt']" />
            </i>

            <span class="pl-3">
              <template v-if="$moment(datePicker.range.start).isSame(datePicker.range.end, 'date')">{{ $moment(datePicker.range.start).format("LL") }}</template>
              <template v-else>{{ $moment(datePicker.range.start).format("LL") }} - {{ $moment(datePicker.range.end).format("LL") }}</template>
            </span>
          </template>

          <template v-slot:default>
            <div class="row mx-0">
              <div class="col-12 border-bottom col-md-8 border-bottom-md-0 border-right-md">
                <date-picker
                  mode="date"
                  class="border-0 my-auto"
                  color="primary"
                  locale="en"
                  is-range
                  is-expanded

                  :value="datePicker.range"
                  :min-date="datePicker.minDate"
                  :max-date="datePicker.maxDate"
                  :input-props="{ class: 'border-0' }"
                  :first-day-of-week="2"

                  @input="onDatePickerInput"
                />
              </div>

              <div class="col pt-5 pt-md-0">
                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().startOf('day').toDate(),
                    end: $moment().endOf('day').toDate()
                  })"
                >
                  Today
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().subtract(1, 'days').startOf('day').toDate(),
                    end: $moment().subtract(1, 'days').endOf('day').toDate()
                  })"
                >
                  Yesterday
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().startOf('isoWeek').toDate(),
                    end: $moment().toDate()
                  })"
                >
                  This week
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                    end: $moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                  })"
                >
                  Last week
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().startOf('month').toDate(),
                    end: $moment().toDate()
                  })"
                >
                  This month
                </div>

                <div
                  class="btn btn-outline-primary btn-block"
                  @click.prevent="onDatePickerInput({
                    start: $moment().subtract(1, 'month').startOf('month').toDate(),
                    end: $moment().subtract(1, 'month').endOf('month').toDate()
                  })"
                >
                  Last month
                </div>
              </div>
            </div>
          </template>
        </b-dropdown>
      </div>
    </div>
    <!-- end::Card header -->

    <!-- begin::Chart -->
    <apexchart
      ref="chart"
      class="card-rounded-bottom h-400 mt-auto"
      :type="chart.type"
      :height="chart.height"
      :options="chart.options"
      :series="chart.series"
    />
    <!-- end::Chart -->
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/datepicker/index.scss';
</style>

<script>
import ApexCharts from 'vue-apexcharts';
import moment from 'moment-timezone';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Equipment from '@/libs/classes/equipment';
import { errorComponentMixin } from '@vedicium/core-vue';

import errorAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  props: {
    equipment: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    errorAlert,

    DatePicker,
    apexchart: ApexCharts,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      data: [],
      datePicker: {
        range: {
          start: null,
          end: null,
        },
        minDate: moment().set('year', 2016).startOf('year').toDate(),
        maxDate: new Date(),
        attributes: [{
          bar: {
            class: 'bg-primary',
          },
          highlight: {
            class: 'bg-primary',
            contentClass: 'bg-success',
          },
        }],
      },

      chart: {
        type: 'area',
        height: 400,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          plotOptions: {},
          colors: [this.$metronic.config.layout.get('colors.theme.base.primary')],
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'solid',
            opacity: [0.5],
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary')],
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            width: 3,
            colors: [this.$metronic.config.layout.get('colors.theme.base.primary')],
          },
          xaxis: {
            type: 'datetime',
            min: null,
            max: null,
            datetimeUTC: false,
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: true,
              formatter (timestamp) {
                return moment(timestamp).format('DD MMM');
              },
            },
            tooltip: {
              enabled: false,
              style: {
                colors: this.$metronic.config.layout.get('colors.gray.gray-500'),
                fontSize: '12px',
                fontFamily: this.$metronic.config.layout.get('font-family'),
              },
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
            labels: {
              show: true,
            },
          },
          markers: {
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary')],
            strokeColor: [this.$metronic.config.layout.get('colors.theme.base.primary')],
            strokeWidth: 3,
          },
        },
        series: [{
          name: 'Sessions',
          data: [],
        }],
      },
    };
  },

  async mounted () {
    // Set start and end from range
    this.$set(this.datePicker, 'range', {
      start: moment().subtract(1, 'month').startOf('day').toDate(),
      end: moment().toDate(),
    });

    try {
      await this.getChartData();
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async onDatePickerInput (range = null) {
      if (!range || !range.start || !range.end) {
        return;
      }

      if (range.start instanceof Date === false || range.end instanceof Date === false) {
        return;
      }

      // Hide dropdown and set range
      this.$refs['range-dropdown'].hide();
      this.$set(this.datePicker, 'range', {
        start: moment(range.start).startOf('day').toDate(),
        end: moment(range.end).endOf('day').toDate(),
      });

      await this.$nextTick();

      // Reload chart data
      try {
        await this.getChartData();
      } catch (e) {
        this.$errors.handle(e, { component: this, ui_element: false });
        console.error(e);
      }
    },

    async getChartData () {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', []);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/sessions/daily/${this.datePicker.range.start.getTime()}/${this.datePicker.range.end.getTime()}`, {
          query: {
            offset: moment.tz.guess(),
          },
        }));
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.configureChartData(this.data ? this.data.results : []);

      return this.data;
    },
    async configureChartData (results = null) {
      // Set date range
      this.$set(this.chart.options.xaxis, 'min', moment(this.datePicker.range.start).valueOf());
      this.$set(this.chart.options.xaxis, 'max', moment(this.datePicker.range.end).startOf('day').valueOf());

      if (!results || Array.isArray(results) === false) {
        this.$set(this.chart, 'series', []);
        return;
      }

      const series = [{
        name: 'Sessions',
        data: [],
      }];

      // Loop through range
      const rangeLoop = {
        start: moment(this.datePicker.range.start),
        end: moment(this.datePicker.range.end),
      };

      do {
        const date = rangeLoop.start.clone();
        series[0].data.push([
          date.valueOf(),
          results.filter((result) => date.isSame(result.date, 'date'), []).map((result) => result.total, 0).reduce((a, b) => a + b, 0),
        ]);
      } while (rangeLoop.start.add(1, 'day').diff(rangeLoop.end) < 0);

      this.$set(this.chart, 'series', series);
    },
  },
};
</script>
