/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import Abstract from '@/libs/classes/abstract';

export default class EquipmentRemark extends Abstract {
  company = {
    guid: null,
    name: null,
  };

  resource = {
    guid: null,
    name: null,
  };

  remark = null;
}
