import jVectorMap from '@/libs/jvectormap';

export default {
  data () {
    return {
      jVectorMap: {
        init: false,
        map: 'world_mill',
        element: null,
        hidden: false,
        resizeTimeout: null,

        options: null,
      },
    };
  },

  updated () {
    if (this.jVectorMap.init === true) this.setJVectorMap(this.jVectorMap.map);
  },
  beforeDestroy () {
    if (this.jVectorMap.init === true) {
      // Delete container
      this.setJVectorMap(null);

      // Remove listener
      window.removeEventListener('resize', this.onWindowResize);
    }
  },

  methods: {
    initJVectorMap (element = null) {
      if (!element) {
        throw new Error('Element undefined');
      }

      // Initialize jVectorMap
      jVectorMap.init();
      this.$set(this.jVectorMap, 'element', window.jQuery(element));
      this.setJVectorMap(this.jVectorMap.map);

      // Add event listener on window resize
      window.addEventListener('resize', this.onWindowResize);

      // Set data
      this.$set(this.jVectorMap, 'init', true);
    },

    onWindowResize () {
      // Rezie jVectorMap
      clearTimeout(this.jVectorMap.resizeTimeout);
      this.jVectorMap.resizeTimeout = setTimeout(() => {
        this.setJVectorMap(this.jVectorMap.map);
      }, 300);
    },

    setJVectorMapOptions (options = {}) {
      this.$set(this.jVectorMap, 'options', options);
    },

    setJVectorMap (map = null) {
      jVectorMap.init();

      this.$set(this.jVectorMap, 'map', map);

      // Set jVectorMap elements
      if (!this.jVectorMap.element) {
        throw new Error('No element found');
      }

      // eslint-disable-next-line no-empty
      try { this.jVectorMap.element.vectorMap('get', 'mapObject').remove(); } catch (e) { }
      this.jVectorMap.element.contents().remove();
      this.jVectorMap.element.empty();

      // Set map
      if (map) {
        jVectorMap.loadMap(map, this.jVectorMap.element);
        this.jVectorMap.element.vectorMap({
          map,
          backgroundColor: null,
          zoomButtons: false,
          zoomOnScroll: false,
          height: '100%',
          ...this.jVectorMap.options,
        });
      }
    },
  },
};
