/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import Abstract from '@/libs/classes/abstract';

export default class InternetRadioStation extends Abstract {
  static uri = '/v2/internet_radio_stations';

  name = null;
  genres = [];
  region = null;
  stream_url = null;
}
