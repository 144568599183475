/* eslint-disable max-classes-per-file */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */

import { AbstractClass } from '@vedicium/core-vue';
import LuxuraLog from '@/libs/classes/luxura_log';

export const ParameterTypes = {
  MENU_NUMERIC: 0,
  MENU_NUMERIC_STEP100: 1,
  MENU_NUMERIC_ULONG: 2,
  MENU_UNIT_TYPE: 3,
  MENU_STANDBY_TEXT: 4,
  MENU_TIME: 5,
  MENU_TIME_HOUR_MIN: 6,
  MENU_TIME_MIN_SEC: 7,
  MENU_DATE: 8,
  MENU_LANGUAGE: 9,
  MENU_PINCODE: 10,
  MENU_NO_YES: 11,
  MENU_REGELING: 12,
  MENU_OFF_ON: 13,
  MENU_AUDIOCHANNEL: 14,
  MENU_FREQUENCY: 15,
  MENU_IDCODE: 16,
  MENU_NUMERIC_SIGNED: 17,
  MENU_DEGREE: 18,
  MENU_LAMP: 19,
  MENU_LAMP_SPECIAL: 20,
  MENU_FILTER: 21,
  MENU_BOOSTER: 22,
  MENU_NUMERIC_OFF: 23,
};

export const ParameterUnits = {
  UNIT_NONE: 0,
  UNIT_SECONDS: 1,
  UNIT_MINUTES: 2,
  UNIT_HOURS: 3,
  UNIT_100HOUR: 4,
  UNIT_PERCENT: 5,
  UNIT_DEGREE: 6,
};

export const MenuIcons = {
  modeltype_Jewel: {
    version_2: [{
      menu: 1,
      icon: ['fas', 'home'],
    }, {
      menu: 2,
      icon: ['fas', 'clock'],
    }, {
      menu: 3,
      icon: ['fas', 'temperature-frigid'],
    }, {
      menu: 4,
      icon: ['fas', 'fan'],
    }, {
      menu: 5,
      icon: ['fas', 'fan-table'],
    }, {
      menu: 6,
      icon: ['fas', 'flower'],
    }, {
      menu: 7,
      icon: ['fas', 'waveform'],
    }, {
      menu: 8,
      icon: ['fas', 'air-conditioner'],
    }, {
      menu: 9,
      icon: ['fas', 'sprinkler'],
    }, {
      menu: 10,
      icon: ['fas', 'coin'],
    }, {
      menu: 11,
      icon: ['fas', 'spinner'],
    }, {
      menu: 12,
      icon: ['fas', 'wave-sine'],
    }, {
      menu: 13,
      icon: ['fas', 'head-side'],
    }, {
      menu: 14,
      icon: ['fas', 'user-circle'],
    }, {
      menu: 15,
      icon: ['fas', 'signal'],
    }],
  },

  modeltype_LXP16: {
    version_2: [{
      menu: 1,
      icon: ['fas', 'home'],
    }, {
      menu: 2,
      icon: ['fas', 'clock'],
    }, {
      menu: 3,
      icon: ['fas', 'temperature-frigid'],
    }, {
      menu: 4,
      icon: ['fas', 'fan'],
    }, {
      menu: 5,
      icon: ['fas', 'fan-table'],
    }, {
      menu: 6,
      icon: ['fas', 'flower'],
    }, {
      menu: 7,
      icon: ['fas', 'waveform'],
    }, {
      menu: 8,
      icon: ['fas', 'air-conditioner'],
    }, {
      menu: 9,
      icon: ['fas', 'sprinkler'],
    }, {
      menu: 10,
      icon: ['fas', 'coin'],
    }, {
      menu: 11,
      icon: ['fas', 'spinner'],
    }, {
      menu: 12,
      icon: ['fas', 'wave-sine'],
    }, {
      menu: 13,
      icon: ['fas', 'head-side'],
    }, {
      menu: 14,
      icon: ['fas', 'user-circle'],
    }, {
      menu: 15,
      icon: ['fas', 'signal'],
    }],
  },

  modeltype_X5X7II: {
    version_2: [{
      menu: 1,
      icon: ['fas', 'home'],
    }, {
      menu: 2,
      icon: ['fas', 'clock'],
    }, {
      menu: 3,
      icon: ['fas', 'temperature-frigid'],
    }, {
      menu: 4,
      icon: ['fas', 'fan'],
    }, {
      menu: 5,
      icon: ['fas', 'fan-table'],
    }, {
      menu: 6,
      icon: ['fas', 'flower'],
    }, {
      menu: 7,
      icon: ['fas', 'waveform'],
    }, {
      menu: 8,
      icon: ['fas', 'air-conditioner'],
    }, {
      menu: 9,
      icon: ['fas', 'sprinkler'],
    }, {
      menu: 10,
      icon: ['fas', 'coin'],
    }, {
      menu: 11,
      icon: ['fas', 'spinner'],
    }, {
      menu: 12,
      icon: ['fas', 'wave-sine'],
    }, {
      menu: 13,
      icon: ['fas', 'head-side'],
    }, {
      menu: 14,
      icon: ['fas', 'user-circle'],
    }, {
      menu: 15,
      icon: ['fas', 'signal'],
    }],
  },

  modeltype_V6V8: {
    version_2: [{
      menu: 1,
      icon: ['fas', 'home'],
    }, {
      menu: 2,
      icon: ['fas', 'clock'],
    }, {
      menu: 3,
      icon: ['fas', 'fan'],
    }, {
      menu: 4,
      icon: ['fas', 'flower'],
    }, {
      menu: 5,
      icon: ['fas', 'waveform'],
    }, {
      menu: 6,
      icon: ['fas', 'sprinkler'],
    }, {
      menu: 7,
      icon: ['fas', 'coin'],
    }, {
      menu: 8,
      icon: ['fas', 'spinner'],
    }, {
      menu: 9,
      icon: ['fas', 'wave-sine'],
    }, {
      menu: 10,
      icon: ['fas', 'signal'],
    }],
  },
};

export class Parameter extends AbstractClass {
  menu = null;
  submenu = null;
  value = null;
  default = null;
  min = null;
  max = null;
  type = null;
  step = null;
  unit = null;
  show = null;
  name = null;
  extra = null;

  // Abstract methods
  merge (body = {}) {
    super.merge(body);

    // Parse numbers
    this.type = Number(this.type);
    this.unit = Number(this.unit);
    this.show = Number(this.show);

    // Parse value as number, if it's a number type
    if ([
      ParameterTypes.MENU_NUMERIC,
      ParameterTypes.MENU_NUMERIC_STEP100,
      ParameterTypes.MENU_NUMERIC_ULONG,
      ParameterTypes.MENU_LANGUAGE,
      ParameterTypes.MENU_NO_YES,
      ParameterTypes.MENU_OFF_ON,
      ParameterTypes.MENU_AUDIOCHANNEL,
      ParameterTypes.MENU_NUMERIC_SIGNED,
      ParameterTypes.MENU_DEGREE,
    ].includes(this.type) === true) {
      this.value = Number(this.value);
    }

    return this;
  }

  // Getters & Setters
  get visible () {
    // Visible by default when 0x10 is set
    // eslint-disable-next-line no-bitwise
    return ((0b00010000 & this.show) !== 0);
  }

  get writeable () {
    // Only writeable when 0x02 (SERVICE_WRITE) or 0x08 (STUDIO_WRITE) is set
    // eslint-disable-next-line no-bitwise
    return ((0b00000010 & this.show) !== 0) || ((0b00001000 & this.show) !== 0);
  }
}

export default class LuxuraLogParameter extends LuxuraLog {
  model_type = null;
  version_id = null;
  parameters = [];

  // Abstract methods
  merge (body = {}) {
    super.merge(body);

    // Save model type and version ID
    this.model_type = this.getModelType();
    this.version_id = this.getVersionID();

    // Flattened parameters
    const flatJSON = this.flatJSON();
    this.parameters = flatJSON.map((row) => new Parameter().merge(row));

    return this;
  }

  // Methods
  flatJSON () {
    if (!this.json_data || !this.json_data.data) {
      return null;
    }

    return Object.keys(this.json_data.data).flatMap((menu) => {
      return Object.keys(this.json_data.data[menu]).flatMap((submenu) => {
        return {
          menu: Number(menu),
          submenu: Number(submenu),
          ...this.json_data.data[menu][submenu],
        };
      });
    });
  }

  getModelType () {
    const flatJSON = this.flatJSON();
    if (!flatJSON) {
      return null;
    }

    const parameterObject = (flatJSON && flatJSON.find((parameter) => (parameter.menu === 0 && parameter.submenu === 0) || (parameter.extra || '').toLowerCase() === 'modeltype')) || null;
    const modelType = parameterObject && parameterObject.name;
    switch (modelType) {
      case 'LUMO': {
        return 'Jewel';
      }

      default: {
        return modelType;
      }
    }
  }

  getVersionID () {
    const flatJSON = this.flatJSON();
    if (!flatJSON) {
      return null;
    }

    const parameterObject = (flatJSON && flatJSON.find((parameter) => (parameter.menu === 0 && parameter.submenu === 1) || (parameter.extra || '').toLowerCase() === 'log version id')) || null;
    return parameterObject && parameterObject.name;
  }

  getMenuIcon (menu = null) {
    if (menu === undefined) {
      return null;
    }

    if (!MenuIcons[`modeltype_${this.model_type}`] || !MenuIcons[`modeltype_${this.model_type}`][`version_${this.version_id}`]) {
      return null;
    }

    return MenuIcons[`modeltype_${this.model_type}`][`version_${this.version_id}`].find((menuIcon) => menuIcon.menu === menu);
  }

  getMenus () {
    const flattenedJSON = this.flatJSON();
    return flattenedJSON && flattenedJSON.map((parameter) => parameter.menu).filter((key, idx, self) => self.indexOf(key) === idx).map((menu) => ({
      menu,
      parameters: flattenedJSON.filter((parameter) => parameter.menu === menu).map((parameter) => new Parameter().merge(parameter)),
    }));
  }

  convertParametersToFile () {
    return this.constructor.convertParametersToFile(this.parameters);
  }

  // Static methods
  static convertParametersToFile (parameters = [], options = {}) {
    return (parameters || []).filter((parameter) => {
      if (parameter instanceof Parameter === false) {
        return false;
      }

      // Remove writeable parameters
      if (parameter.writeable === false) {
        return false;
      }

      // Exclude time & date
      if (options && options.exclude_datetime === true) {
        if ([ParameterTypes.MENU_TIME, ParameterTypes.MENU_DATE].includes(parameter.type)) {
          return false;
        }
      }

      // Include invisible items
      if (!options || options.include_invisible !== true) {
        if (parameter.visible === false) {
          return false;
        }
      }

      return true;
    }).sort((a, b) => {
      if (a.menu === b.menu) {
        return a.submenu - b.submenu;
      }
      return a.menu - b.menu;
    }).map((parameter) => {
      return `${parameter.menu};${parameter.submenu};${parameter.value};`;
    }).join('\n') || null;
  }
}
