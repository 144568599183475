import LuxuraLog from '@/libs/classes/luxura_log';

export default class LuxuraLogSession extends LuxuraLog {
  session = {
    session_time: null,
    pause_time: null,
    pause_times: null,

    minute_counters: [],
    minute_counter_delta: [],
  }
}
