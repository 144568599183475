/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import Abstract from '@/libs/classes/abstract';
import core from '@vedicium/core-vue';

export default class EquipmentSettingsRequest extends Abstract {
  equipment_guid = null;

  resource = {
    guid: null,
    name: null,
  };

  company = {
    guid: null,
    name: null,
  };

  mutations = null;

  async download (options = {}) {
    await core.http.downloadFile((options && options.filename) || 'settings.mutations.csv', this.mutations, 'text/csv');
  }
}
