<template>
  <!-- begin::Content -->
  <div class="row">
    <!-- begin::Dashboard -->
    <div class="col">
      <!-- begin::Top row -->
      <div class="row">
        <!-- begin::Device status -->
        <div class="col-12 mt-5 col-xl mt-xl-0">
          <devices-status-card
            ref="devicesStatusCard"

            class="h-100"
          />
        </div>
        <!-- end::Device status -->

        <!-- begin::Key figures -->
        <div v-if="$auth.hasPermission('equipment.statistics.sessions')" class="col-12 mt-5 col-xl mt-xl-0">
          <key-figures-card
            ref="keyFiguresCard"

            class="h-100"
          />
        </div>
        <!-- end::Key figures -->

        <!-- begin::Notifications -->
        <div class="col-12 mt-5 col-xl mt-xl-0">
          <notifications-card
            ref="notificationsCard"

            class="h-100"
          />
        </div>
        <!-- end::Notifications -->
      </div>
      <!-- end::Top row -->

      <!-- begin::Bottom row -->
      <div class="row mt-5">
        <div v-if="$auth.hasPermission('equipment.statistics.sessions')" class="col-12 col-xl">
          <sessions-card
            ref="sessionsCard"

            class="h-100"
          />
        </div>

        <div class="d-none d-xl-block col mt-5 mt-xl-0">
          <locations-card
            ref="locationsCard"

            class="h-100"
          />
        </div>
      </div>
      <!-- end::Bottom row -->
    </div>
    <!-- end::Dashboard -->
  </div>
  <!-- end::Content -->
</template>

<script>
import devicesStatusCard from './components/devices-status.card.vue';
import keyFiguresCard from './components/key-figures.card.vue';
import notificationsCard from './components/notifications.card.vue';

import sessionsCard from './components/sessions.card.vue';
import locationsCard from './components/locations.card.vue';

export default {
  components: {
    devicesStatusCard,
    keyFiguresCard,
    notificationsCard,

    sessionsCard,
    locationsCard,
  },

  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Dashboard' }]);
  },
};
</script>
