<template>
  <div class="card card-custom">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop rounded" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Error -->
    <template v-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop" />
        <div class="loading-block w-75">
          <error-translation-alert
            ref="errorTranslationAlert"
            :error="activeError"
            class="shadow"
          />
        </div>
      </div>
    </template>
    <!-- end::Error -->

    <div class="card-header flex-wrap">
      <div class="card-title">
        <h3 class="card-label">
          Locations
          <span class="text-muted pt-2 font-size-sm d-block">Locations of devices that belong to this company.</span>
        </h3>
      </div>

      <div class="card-toolbar">
        <div class="input-group w-250px">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i>
                <font-awesome-icon :icon="['fas', 'globe']" />
              </i>
            </span>
          </div>
          <select class="form-control" v-model.trim="map">
            <optgroup v-for="group in map_options" :key="`map.group.${group.name}`" :label="group.name">
              <option v-for="option in group.options" :key="`map.group.${group.name}.options.${option.name}`" :value="option.map">{{ option.name }}</option>
            </optgroup>
          </select>
        </div>
      </div>
    </div>

    <div
      ref="jVectorMap"
      class="card-body min-h-400px w-100 p-3 d-flex"
    />
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/plugins/jvectormap/index";
</style>

<script>
import jVectorMap from '@/mixins/jvectormap';
import { errorComponentMixin } from '@vedicium/core-vue';
import equipmentService from '@/libs/equipment';

import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  name: 'locationsCard',
  mixins: [jVectorMap, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    const vm = this;

    return {
      isMounted: false,
      isLoading: false,
      data: null,

      map: 'world_mill',
      map_options: [{
        name: 'World',
        options: [{
          name: 'World map',
          map: 'world_mill',
        }],
      }, {
        name: 'Europe',
        options: [{
          name: 'Map of Europe',
          map: 'europe_mill',
          sort: 1,
        }, {
          name: 'Belgium',
          map: 'be_mill',
          autoselect: 'Belgium',
        }, {
          name: 'Denmark',
          map: 'dk_mill',
          autoselect: 'Denmark',
        }, {
          name: 'France',
          map: 'fr_regions_mill',
          autoselect: 'France',
        }, {
          name: 'Germany',
          map: 'de_mill',
          autoselect: 'Germany',
        }, {
          name: 'The Netherlands',
          map: 'nl_mill',
          autoselect: 'Netherlands',
        }, {
          name: 'Norway',
          map: 'no_mill',
          autoselect: 'Norway',
        }, {
          name: 'Spain',
          map: 'es_mill',
          autoselect: 'Spain',
        }, {
          name: 'Sweden',
          map: 'se_mill',
          autoselect: 'Sweden',
        }, {
          name: 'Italy',
          map: 'it_regions_mill',
          autoselect: 'Italy',
        }, {
          name: 'Portugal',
          map: 'pt_mill',
          autoselect: 'Portugal',
        }, {
          name: 'Austria',
          map: 'at_mill',
          autoselect: 'Austria',
        }, {
          name: 'Poland',
          map: 'pl_mill',
          autoselect: 'Poland',
        }, {
          name: 'Switzerland',
          map: 'ch_mill',
          autoselect: 'Switzerland',
        }, {
          name: 'United Kingdom',
          map: 'uk_countries_mill',
          autoselect: 'United Kingdom',
        }, {
          name: 'Russia',
          map: 'ru_mill',
          autoselect: 'Russia',
        }].sort((a, b) => b.sort || a.name.localeCompare(b.name)),
      }, {
        name: 'North America',
        options: [{
          name: 'Map of North America',
          map: 'north_america_mill',
          sort: 1,
        }, {
          name: 'Canada',
          map: 'ca_mill',
          autoselect: 'Canada',
        }, {
          name: 'United States of America',
          map: 'us_mill',
          autoselect: 'United States',
        }].sort((a, b) => b.sort || a.name.localeCompare(b.name)),
      }, {
        name: 'South America',
        options: [{
          name: 'Map of South America',
          map: 'south_america_mill',
          sort: 1,
        }].sort((a, b) => b.sort || a.name.localeCompare(b.name)),
      }, {
        name: 'Asia',
        options: [{
          name: 'Map of Asia',
          map: 'asia_mill',
          sort: 1,
        }, {
          name: 'South Korea',
          map: 'kr_mill',
          autoselect: 'South Korea',
        }].sort((a, b) => b.sort || a.name.localeCompare(b.name)),
      }, {
        name: 'Africa',
        options: [{
          name: 'Map of Africa',
          map: 'africa_mill',
          sort: 1,
        }].sort((a, b) => b.sort || a.name.localeCompare(b.name)),
      }, {
        name: 'Oceania',
        options: [{
          name: 'Map of Oceania',
          map: 'oceania_mill',
          sort: 1,
        }, {
          name: 'Australia',
          map: 'au_mill',
          autoselect: 'Australia',
        }].sort((a, b) => b.sort || a.name.localeCompare(b.name)),
      }],
      jVectorMapOptions: {
        zoomOnScroll: true,
        // onMarkerTipShow (e, el, code) {
        //   const index = Number(code);
        //   if (!vm.jVectorMapOptions.markers || !vm.jVectorMapOptions.markers[index]) {
        //     return;
        //   }

        //   const marker = vm.jVectorMapOptions.markers[index];
        //   let text = `${marker.config.country}: ${marker.config.count}`;

        //   if (marker.config.city) text = `${marker.config.city}, ${text}`;

        //   el.text(text);
        // },
        onMarkerClick (event, index) {
          if (!vm.jVectorMapOptions.markers || !vm.jVectorMapOptions.markers[index]) {
            return;
          }

          const marker = vm.jVectorMapOptions.markers[index];
          const route = { path: '/sunbeds/overview', query: {} };

          // Add route query's
          if (marker.config.city) route.query['filter[location.city]'] = marker.config.city;
          if (marker.config.country) route.query['filter[location.country]'] = marker.config.country;

          vm.$router.push(route);
        },
      },
    };
  },
  watch: {
    map (value) {
      this.onMapChange(value);
    },
  },

  async mounted () {
    try {
      await this.getEquipmentLocations();
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }

    await this.$nextTick();

    // Set map based on data
    this.$set(this, 'map', this.getMapBasedOnLocations(this.data));

    // Initiate jVectorMap
    this.setJVectorMapOptions(this.jVectorMapOptions);
    this.initJVectorMap(this.$refs.jVectorMap);
  },

  methods: {
    async getEquipmentLocations (options = {}) {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', null);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/locations`, {
          ...options,
          query: {
            ...(options.query || {}),
            include_states: true,
          },
        }));
        this.$set(this.jVectorMapOptions, 'markers', this.generateEquipmentMarkers(this.data));
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.data;
    },

    getMapBasedOnLocations (locations = []) {
      const countries = (locations || []).map((country) => country.name, []);

      // Don't select if no countries found or more than 1 is found
      if (countries.length === 0 || countries.length > 1) {
        return this.map;
      }

      const value = countries[0];
      const options = (this.map_options || []).flatMap((group) => group.options, []);
      const autoSelectOption = (options || []).find((option) => option.autoselect === value);
      if (!autoSelectOption) {
        return this.map;
      }

      return autoSelectOption.map;
    },

    generateEquipmentMarkers (locations = []) {
      const markers = [];

      // Loop through every country
      (locations || []).forEach((country) => {
        // Loop through every city of that country
        (country.cities || []).forEach((city) => {
          const marker = {
            latLng: [city.geometry.lat, city.geometry.lon],
            name: `${city.name}, ${country.name}`,

            config: {
              city: city.name,
              country: country.name,
              states: city.states,
            },

            style: {
              fill: this.$metronic.config.layout.get('colors.theme.base.success'),
            },
          };

          if (city.states) {
            switch (true) {
              // Set marker to danger color if one of them needs attention
              case city.states.find((row) => equipmentService.attentionStates.includes(row.state)) !== undefined:
                marker.style.fill = this.$metronic.config.layout.get('colors.theme.base.danger');
                break;

              case city.states.find((row) => equipmentService.inSessionStates.includes(row.state)) !== undefined:
                marker.style.fill = this.$metronic.config.layout.get('colors.theme.base.warning');
                break;

              default:
                marker.style.fill = this.$metronic.config.layout.get('colors.theme.base.success');
                break;
            }
          }

          // Add marker
          markers.push(marker);
        });
      });

      return markers;
    },

    onMapChange (map = null) {
      if (!map) {
        return;
      }

      this.setJVectorMap(map);
      this.$set(this, 'map', map);
    },
  },
};
</script>
