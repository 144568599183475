<template>
  <div class="card card-custom">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop rounded" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Error -->
    <template v-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop" />
        <div class="loading-block w-75">
          <error-translation-alert
            ref="errorTranslationAlert"
            :error="activeError"
            class="shadow"
          />
        </div>
      </div>
    </template>
    <!-- end::Error -->

    <div class="card-body d-flex flex-column p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer pb-0">
        <div class="d-flex flex-column mr-2">
          <span class="text-dark font-weight-bold font-size-h5">Sunbeds</span>
        </div>
      </div>

      <div class="px-10 py-5 mt-n10">
        <div class="pt-0 d-flex align-items-center">
          <div class="d-flex justify-content-center my-auto">
            <router-link :to="`/sunbeds/overview`" class="card-title font-weight-bolder text-dark-75 font-size-h1 mb-0 text-hover-primary">
              {{ (data && data.total || 0) }}
            </router-link>
          </div>

          <div v-if="data && data.results.length > 0" class="d-flex align-items-center justify-content-end flex-grow-1 h-100px">
            <div class="mr-10">
              <div class="d-flex align-items-center mb-5">
                <i class="label label-dot label-lg label-success mr-3" />
                <span class="font-weight-bold">Available</span>
              </div>

              <div class="d-flex align-items-center mb-5">
                <i class="label label-dot label-lg label-warning mr-3" />
                <span class="font-weight-bold">In session</span>
              </div>

              <div class="d-flex align-items-center">
                <i class="label label-dot label-lg label-danger mr-3" />
                <span class="font-weight-bold">Attention</span>
              </div>
            </div>

            <apexchart
              ref="chart"
              class="d-flex"
              :type="chart.type"
              :height="chart.height"
              :width="chart.width"
              :options="chart.options"
              :series="chart.series"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts';
import { errorComponentMixin } from '@vedicium/core-vue';
import equipmentService from '@/libs/equipment';

import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    apexchart: ApexCharts,
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,
      data: null,

      chart: {
        type: 'donut',
        height: 130,
        width: 100,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: false,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                size: '75%',
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 2,
          },
          states: {
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          labels: ['Available', 'In session', 'Attention'],
          colors: [this.$metronic.config.layout.get('colors.theme.base.success'), this.$metronic.config.layout.get('colors.theme.base.warning'), this.$metronic.config.layout.get('colors.theme.base.danger')],
        },
        series: [],
      },
    };
  },

  async mounted () {
    try {
      await this.getChartData();
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    async getChartData (options = {}) {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', null);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/states`, { ...options }));
        this.setChartData(this.data.results, options);
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.data;
    },

    setChartData (data = []) {
      // Clear chart data if no data is set
      if (!data || !Array.isArray(data)) {
        this.$set(this.chart, 'series', []);
        return [];
      }

      // Gather data
      this.$set(this.chart, 'series', [
        data.filter((row) => equipmentService.availableStates.includes(row.state), []).map((row) => row.total, 0).reduce((a, b) => a + b, 0),
        data.filter((row) => equipmentService.inSessionStates.includes(row.state), []).map((row) => row.total, 0).reduce((a, b) => a + b, 0),
        data.filter((row) => equipmentService.attentionStates.includes(row.state), []).map((row) => row.total, 0).reduce((a, b) => a + b, 0),
      ]);

      return data;
    },
  },
};
</script>
