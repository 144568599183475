/* eslint-disable lines-between-class-members */
import Abstract from '@/libs/classes/abstract';

export default class UserPersonalToken extends Abstract {
  static uri = '/v2/users/tokens';

  name = null;
  scopes = [];

  // Will only be set when it's created
  token = null;
}
