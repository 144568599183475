/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export default class jVectorMap {
  static mapDirectory = 'assets/js/jvectormap';

  static init () {
    // Check if jQuery and jQuery VectorMap are included already
    // Checking it here, because it's a component
    if (typeof window.jQuery === typeof undefined) {
      window.jQuery = require('jquery');
    }

    if (typeof window.jQuery.fn.vectorMap === typeof undefined) {
      window.jvm = require('jvectormap-next')(window.jQuery);
    }
  }

  static loadMap (map, element) {
    if (map && element) {
      try {
        element.vectorMap({ map });
        element.vectorMap('get', 'mapObject').remove();
        element.contents().remove();
        element.empty();
      } catch (e) {
        require(`@/${this.mapDirectory}/${map}.js`);
      }
    }
  }
}
