<template>
  <div class="card card-custom">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop rounded" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::loading -->

    <!-- begin::Error -->
    <template v-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop" />
        <div class="loading-block w-75">
          <error-translation-alert
            ref="errorTranslationAlert"
            :error="activeError"
            class="shadow"
          />
        </div>
      </div>
    </template>
    <!-- end::Error -->

    <div class="card-body d-flex flex-column p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer pb-0">
        <div class="d-flex flex-column mr-2">
          <span class="text-dark font-weight-bold font-size-h5">Key figures</span>
        </div>

        <div class="d-flex flex-column mr-2">
          <span class="text-muted">{{ dateRange.from.format('LL') }} - {{ dateRange.till.format('LL') }}</span>
        </div>
      </div>

      <div class="px-10 mt-n5 pb-5">
        <div class="navi navi-icon-circle navi-spacer-x-0">
          <div class="navi-item">
            <div class="navi-link rounded py-1">
              <div class="symbol symbol-50 mr-3">
                <div class="symbol-label bg-light-warning">
                  <i :class="['icon-lg text-warning']">
                    <font-awesome-icon :icon="['fas', 'sun']" />
                  </i>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-size-lg"><strong>{{ Math.round(averageSessionsPerEquipmentPerDay || 0) }}</strong> {{ format.capitalizeFirstLetter($tc('plural.sessions', Math.round(averageSessionsPerEquipmentPerDay || 0))) }}</div>
                <div class="text-muted">Average number of sessions per sunbed per day</div>
              </div>
            </div>
          </div>

          <div class="navi-item">
            <div class="navi-link rounded py-1">
              <div class="symbol symbol-50 mr-3">
                <div class="symbol-label bg-light-info">
                  <i :class="['icon-lg text-info']">
                    <font-awesome-icon :icon="['fas', 'hourglass-half']" />
                  </i>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-size-lg"><strong>{{ Math.floor(averageSessionTimePerEquipment / 60) }}</strong> {{ format.capitalizeFirstLetter($tc('plural.minutes', Math.round(averageSessionTimePerEquipment / 60))) }}</div>
                <div class="text-muted">Average session time per sunbed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { errorComponentMixin } from '@vedicium/core-vue';
import format from '@/libs/format';

import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      dateRange: {
        from: moment().subtract(1, 'month').startOf('day'),
        till: moment(),
      },

      data: null,

      format,
    };
  },
  computed: {
    daysInRange () {
      return this.dateRange.till.diff(this.dateRange.from, 'days');
    },

    averageSessionsPerEquipmentPerDay () {
      if (!this.data || !this.data.sessions) {
        return null;
      }

      return (this.data.sessions.total / this.data.equipment.total / this.daysInRange) || 0;
    },

    averageSessionTimePerEquipment () {
      if (!this.data || !this.data.sessions) {
        return null;
      }

      return (this.data.sessions.session_time / this.data.sessions.total / this.data.equipment.total) || 0;
    },
  },

  async mounted () {
    try {
      this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/key-figures`, {
        query: {
          offset: moment.tz.guess(),

          since: this.dateRange.from.valueOf(),
          till: this.dateRange.till.valueOf(),
        },
      }));
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {

  },
};
</script>
