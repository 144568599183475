/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import Abstract from '@/libs/classes/abstract';

export default class DevicePing extends Abstract {
  static uri = '/v2/device_pings';

  cpu = {
    load: [],
    temperature: null,
  };
}
