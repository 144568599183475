<template>
  <component v-if="notification" :is="(notification.route ? 'router-link' : 'a')" :to="notification.route" class="navi-item">
    <div class="navi-link rounded">
      <div class="symbol symbol-50 mr-3">
        <div class="symbol-label">
          <i :class="['icon-lg', (`text-${notification.variant || 'primary'}`)]">
            <font-awesome-icon :icon="['fas', notification.icon]" :spin="notification.spin === true" />
          </i>
        </div>
      </div>
      <div class="navi-text">
        <div class="font-size-lg">{{ notification.text }}</div>
        <span
          v-if="notification.description"
          class="text-muted"
          v-html="notification.description"
        />
      </div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      default: null,
    },
  },
};
</script>
