const VNodeToHTML = (VNode) => {
  const HTML = document.createElement(VNode.tag);
  VNode.data.class.forEach((classList) => {
    if (Object.keys(classList).length > 0) {
      Object.keys(classList).forEach((className) => {
        HTML.classList.add(className);
      });
    }
  });

  Object.keys(VNode.data.attrs).forEach((attribute) => {
    HTML.setAttribute(attribute, VNode.data.attrs[attribute]);
  });

  // Add children
  VNode.children.forEach((child) => {
    HTML.innerHTML += VNodeToHTML(child).outerHTML;
  });

  return HTML;
};

export default VNodeToHTML;
